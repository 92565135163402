<template>
  <div class="vgt__pagination p-1">
    <div
      class="d-flex flex-column"
    >
      <div class="d-flex flex-row ml-auto pb-1">
        <div class="align-self-center">
          {{ $t('RowsPerPage') }} :
        </div>
        <div>
          <b-form-select
            v-model="perPageTotal"
            :options="perPageDropdown"
            size="sm"
            @change="customPerPageChange"
          />
        </div>
      </div>
      <div class="vgt__pagination__right ml-auto">
        <b-pagination
          v-if="totalRowsTotal > 0"
          :value="myCurrentPage"
          :total-rows="totalRowsTotal"
          :per-page="perPageTotal"
          size="lg"
          pills
          align="right"
          first-number
          last-number
          first-text="⏮"
          prev-text="⏪"
          next-text="⏩"
          last-text="⏭"
          @change="customPageChange"
        />
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    perPage: { type: Number, required: true },
    totalRows: { type: Number, required: true },
    currentPage: { type: [Number, String], required: true },
    perPageDropdown: { type: Array, required: true },
    pageChanged: {
      type: Function,
      required: true,
    },
    perPageChanged: {
      type: Function,
      required: true,
    },
  },
  computed: {
    perPageTotal: {
      get() {
        return this.perPage
      },
      set(val) {
        this.$emit('update:perPage', Number(val))
      },
    },
    totalRowsTotal() {
      return this.totalRows
    },
    myCurrentPage: {
      get() {
        return this.currentPage
      },
      set(val) {
        this.$emit('update:currentPage', Number(val))
      },
    },
  },
  methods: {
    customPageChange(customCurrentPage) {
      this.pageChanged({ currentPage: customCurrentPage });
    },
    customPerPageChange(customPerPage) {
      this.perPageChanged({ currentPerPage: customPerPage });
    },
  },
}
</script>

<style>

</style>
