<template>
  <div class="setting-compact-mode">
    <b-form-checkbox
      v-model="compactMode"
      class="custom-control-success"
      switch
    >
      <span class="h5 font-weight-bolder">Mobile</span>
    </b-form-checkbox>
  </div>
</template>

<script>
import store from '@/store'

export default {
  data() {
    return {
    }
  },
  computed: {
    compactMode: {
      get() {
        const mode = store.getters['viewtable/compactMode']
        return Boolean(mode)
      },
      set(val) {
        store.commit('viewtable/compactMode', Boolean(val))
      },
    },
  },
  mounted() {},
}
</script>

<style>
.setting-compact-mode {
  display: none;
}
@media (max-width: 576px) {
  .setting-compact-mode {
    display: inline;
  }
}
</style>
