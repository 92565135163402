<template>
  <div class="table">
    <vue-good-table
      ref="table"
      :mode="tableMode"
      :pagination-options="{
        enabled: paginationEnabled,
        position: position,
        perPage: totalPerPage,
        perPageDropdown: perPageDropdown,
        dropdownAllowAll: false,
        mode: paginationMode,
        infoFn: (params) => `${params}`,
        setCurrentPage: serverParams.page
      }"
      :select-options="selectOption"
      :total-rows="totalRecords"
      :rows="rows"
      :columns="columns"
      :is-loading="isLoading"
      :line-numbers="isLineNumber"
      theme="black-rhino "
      class="vgt-table condensed"
      :columns-top="columnsTop"
      :compact-mode="compactMode"
      :sort-options="defaultSortOptions || sortOptions"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-selected-rows-change="selectionChanged"
    >

      <div slot="selected-row-actions">
        <slot name="my-selected-row-actions" />
      </div>

      <!-- <template v-slot:item.order="{ item }">
      {{
        'items' in $attrs &&
          $attrs.items
            .map(function(x) {
              return x.id
            })
            .indexOf(item.id) + 1
      }}
    </template> -->

      <template slot="loadingContent">
        <div
          class="spinner-border text-primary"
          style="width: 3rem; height: 3rem;"
          role="status"
        >
          <span class="sr-only">{{ $t('Loading') }}...</span>
        </div>
      </template>

      <template
        slot="table-column"
        slot-scope="props"
      >
        <span>
          {{ $t(props.column.label) }}
        </span>
      </template>

      <div slot="emptystate">
        <div class="d-flex justify-content-center">
          <div>
            <span class="title">{{ $t('NoData') }}</span>
          </div>
        </div>
      </div>

      <template
        slot="column-filter"
        slot-scope="props"
      >
        <div
          v-if="isFilterable(props.column)"
          class="search"
        >
          <b-input-group
            size="sm"
          >
            <b-input-group-prepend is-text>
              <b-icon
                icon="search"
              />
            </b-input-group-prepend>
            <b-form-input
              type="search"
              :placeholder="$t('Search')"
              @keyup.enter="handleCustomFilter(props, $event.target.value)"
              @input="handleCustomFilter(props, $event)"
            />
          </b-input-group>
        </div>
      </template>

      <template
        slot="pagination-top"
        slot-scope="props"
      >
        <VueGoodTablePagination
          :per-page.sync="totalPerPage"
          :total-rows="props.total"
          :current-page.sync="serverParams.page"
          :per-page-dropdown="perPageDropdown"
          :page-changed="props.pageChanged"
          :per-page-changed="props.perPageChanged"
        />
      </template>
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <VueGoodTablePagination
          :per-page.sync="totalPerPage"
          :total-rows="props.total"
          :current-page.sync="serverParams.page"
          :per-page-dropdown="perPageDropdown"
          :page-changed="props.pageChanged"
          :per-page-changed="props.perPageChanged"
        />
      </template>

      <template
        v-for="(_, slot) of $scopedSlots"
        v-slot:[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        />
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import store from '@/store'
import { VueGoodTable } from 'vue-good-table-custom'
import VueGoodTablePagination from '@/components/VueGoodTablePagination'
import debounce from 'lodash/debounce'

export default {
  components: {
    VueGoodTable,
    VueGoodTablePagination,
  },
  props: {
    totalRecords: { type: Number, required: true },
    rows: { type: Array, required: true },
    columns: { type: Array, required: true },
    isLineNumber: { type: Boolean, required: false, default: true },
    isLoading: { type: Boolean, required: false, default: false },
    paginationEnabled: { type: Boolean, required: false, default: true },
    paginationMode: { type: String, required: false, default: 'records' },
    position: { type: String, required: false, default: 'both' },
    columnsTop: { type: Array, required: false, default: () => [] },
    tableMode: { type: String, required: false, default: "remote" },
    selectEnabled: { type: Boolean, required: false, default: false },
    sortOptions: { type: Object, required: false, default: () => ({ enabled: true }) },
    page: {
      type: [Number, String],
      required: false,
      default: 1,
    },
  },
  data() {
    return {
      perPageDropdown: [100, 300, 500],
      serverParams: {
        page: 1, // what page I want to show
        perPage: 100, // how many items I'm showing per page

        // a map of column filters example: {name: 'john', age: '20'}
        columnFilters: JSON.stringify({}),

        // {
        //   field: '', // example: 'name'
        //   type: '', // 'asc' or 'desc'
        // },
        sort: JSON.stringify({}),

      },

      defaultRows: null,
      // defaultSortOptions: null,
      defaultSortOptions: { enabled: true },
    }
  },
  computed: {
    compactMode() {
      const mode = store.getters['viewtable/compactMode']
      return Boolean(mode)
    },
    totalPerPage: {
      get() {
        return this.serverParams.perPage
      },
      set(val) {
        this.serverParams.perPage = val
      },
    },
    selectOption() {
      return {
        enabled: this.selectEnabled,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'select__table',
        selectionText: this.$t('Item Selected'),
        clearSelectionText: this.$t('Clear'),
        disableSelectInfo: false, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }
    },
  },
  watch: {
    page(newValue, oldValue) {
      this.serverParams.page = newValue
    },
  },
  created() {
    const querys = this.$route.query
    const querysKeys = Object.keys(this.$route.query)
    const allowQuery = Object.keys(this.serverParams)

    if (Array.isArray(querysKeys)) {
      for (let index = 0; index < querysKeys.length; index += 1) {
        const key = querysKeys[index];
        if (allowQuery.includes(key)) {
          const value = querys[key]
          if (['page', 'perPage'].includes(key) && !Number.isNaN(value) && Number(value) > 0) {
            this.serverParams[key] = Number(value)
          } else {
            this.serverParams[key] = value
          }
        }
      }
    }
  },
  methods: {
    isFilterable(column) {
      return column.filterOptions
        && column.filterOptions.enabled;
    },
    isCustomFilter(props) {
      return props?.column?.filterOptions?.customFilter ?? false
    },
    // eslint-disable-next-line prefer-arrow-callback
    handleCustomFilter: debounce(function _debounce(props, value) {
      props.updateFilters(props.column, value)
    }, 400),
    getParams() {
      return this.serverParams
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      // console.log('onPageChange', params)
      this.updateParams({ page: params.currentPage });
      this.onLoadData();
    },

    onPerPageChange(params) {
      // console.log('onPerPageChange', params)
      this.updateParams({ perPage: params.currentPerPage, page: 1 });
      this.onLoadData();
    },

    onSortChange(params) {
      if (this.tableMode === 'remote') {
      // #server sort
        this.updateParams({
          sort: JSON.stringify({
            field: params[0]?.field ?? '',
            type: params[0]?.type ?? '',
          }),
          page: 1,
        });
        this.onLoadData();
      } else {
        const field = params[0]?.field ?? '' // field
        const type = params[0]?.type ?? '' // none,asc,desc
        // console.log('field', { field, type });

        if (!this.defaultRows) {
          this.defaultRows = this.rows
        }

        if (type === 'none') {
          this.rows = this.defaultRows
          return
        }

        this.rows = this.rows.sort((a, b) => {
          if (type === 'desc') {
            return b[field] - a[field]
          }

          return a[field] - b[field]
        })
      }
    },

    onColumnFilter(params) {
      // console.log('onColumnFilter', params)
      this.updateParams({
        columnFilters: JSON.stringify(params.columnFilters),
        page: 1,
      });
      this.onLoadData();
    },

    // load items is what brings back the rows from server
    onLoadData() {
      this.$emit('onLoadData', this.serverParams)
    },

    selectionChanged(selected) {
      this.$emit('selectionChanged', selected.selectedRows)
    },

    getSelectionRows() {
      try {
        return this.$refs.table.selectedRows
      } catch (error) {
        return []
      }
    },
  },
}
</script>

<style>

</style>
